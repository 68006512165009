import React, { useCallback } from 'react';
import { CollectionGridDeepLinkAsset, CollectionGridMediaAsset } from 'types';

import { UIDAPresentationTypeEnum } from '@kaltura-ott/tvpil';
import { UserActionType } from '@kaltura-ott/tvpil-analytics';
import { CategoryStore, CollectionAsset, CollectionLinkAsset, CollectionProgramAsset } from '@kaltura-ott/tvpil-shared';

import { useCardRecordInfo } from 'components/widgets/Card/hooks';
import { DescriptionWithAge } from 'components/widgets/Modal/PinCodeModal/DescriptionWithAge/DescriptionWithAge';
import { PinCodeModalTypes } from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalTypesEnum';
import PinCodeModalV2 from 'components/widgets/Modal/PinCodeModalV2/PinCodeModalV2';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { LAST_SCROLL_Y_POSITION } from 'consts';
import { useModalContext } from 'context/modal/modal';
import { useStableReactRouter } from 'context/stableReactRouterContext/stableReactRouterContext';
import {
  useAppFindPageStore,
  useIsUrlToFullPlayer,
  useParentalPinStore,
  usePlayProviderAsset,
  useSettingsStore,
} from 'hooks';
import { globalStorage } from 'services/globalStorage';
import { resolvePathToDetailsPage, userActionEvents } from 'utils';

interface Props {
  data: CollectionAsset | CollectionProgramAsset | undefined;
  link: string;
  mediaTypeParam: string;
  isExternalProviderAsset: boolean;
  isLinkAsAsset: boolean;
  isPurchasable: boolean;
  collectionType: string | undefined;
  isProgramAsset: boolean;
  isTypeOfSeries: boolean;
  isCollectionEpisode: boolean;
  isCollectionInAppAdultLink: boolean;
  isAdult: boolean;
  isCatchupSeries: boolean;
  imageHeight: number;
}

const useCollectionCardClickHandler = ({
  data,
  link,
  mediaTypeParam,
  isExternalProviderAsset,
  isLinkAsAsset,
  isPurchasable,
  collectionType,
  isProgramAsset,
  isTypeOfSeries,
  isCollectionEpisode,
  isCollectionInAppAdultLink,
  isAdult,
  imageHeight,
}: Props) => {
  const { onCardClick } = useRailContext();
  const { findPageStore } = useAppFindPageStore();
  const { navigate } = useStableReactRouter();
  const { settingsStore } = useSettingsStore();
  const parentalPinStore = useParentalPinStore();
  const isUrlToFullPlayer = useIsUrlToFullPlayer(link) || false;
  const { parentalRating } = data as CollectionGridMediaAsset;
  const { isRecord, isSeriesType } = useCardRecordInfo({
    data,
    isProgramAsset,
    isTypeOfSeries,
    isCollectionEpisode,
  });

  // TODO: maybe change ref to css transform state
  const modalContext = useModalContext();
  const playProviderAsset = usePlayProviderAsset(data as CollectionGridDeepLinkAsset, modalContext);

  return useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      globalStorage.clearDetailsAsset(); // Temporary solution to avoid opening a detail page with an asset saved in globalStorage

      sessionStorage.setItem(LAST_SCROLL_Y_POSITION, window.scrollY.toString());

      if (onCardClick) {
        onCardClick(data?.id!);
      }

      const isAdultPinNotEntered = !parentalPinStore.isAdultPinEntered;
      const isAdultBlocked = isAdultPinNotEntered && isAdult;
      const isRestricted = !!(
        (isAdult || isCollectionInAppAdultLink) &&
        isAdultPinNotEntered &&
        !isExternalProviderAsset
      );
      const page = link && (findPageStore(link.split('?')[0]) as CategoryStore);

      if (isExternalProviderAsset) {
        playProviderAsset(e, isExternalProviderAsset);
        return;
      }

      if (isRestricted) {
        e.preventDefault();
        e.stopPropagation();

        let type = PinCodeModalTypes.RestrictedContent;
        let inAppLink;

        if (isCollectionInAppAdultLink) {
          type = PinCodeModalTypes.RestrictedContentForLink;
          inAppLink = (data as CollectionLinkAsset)?.inAppLink!;
        }

        modalContext.openModal(
          <PinCodeModalV2
            callback={(success: boolean) => {
              if (success && isCollectionInAppAdultLink && link) {
                navigate(link);
              }
            }}
            parentalRating={parentalRating}
            context={UIDAPresentationTypeEnum.applications}
            inAppLink={inAppLink}
            type={type}
          />,
        );

        return;
      }

      if (isLinkAsAsset) {
        if (page && isAdultBlocked) {
          e.preventDefault();

          modalContext.openModal(
            <PinCodeModalV2
              parentalRating={parentalRating}
              callback={(success: boolean) => {
                if (success && link) {
                  navigate(link);
                }
              }}
              context={UIDAPresentationTypeEnum.applications}
              type={PinCodeModalTypes.RestrictedContent}
            />,
          );
          return;
        }
      }

      if (isPurchasable && !isRecord && !isSeriesType) {
        e.preventDefault();
        if (isLinkAsAsset) return;

        userActionEvents(UserActionType.details, data?.id, mediaTypeParam);

        navigate(
          resolvePathToDetailsPage({
            id: data?.id!,
            mediaTypeParam: mediaTypeParam.toLowerCase(),
            subtype: collectionType,
          }),
          {
            state: {
              lastScrollYPosition: window.scrollY + imageHeight,
            },
          },
        );
        return;
      }

      if (isUrlToFullPlayer && parentalPinStore.isBlockedByParentalRating(parentalRating!)) {
        e.preventDefault();

        modalContext.openModal(
          <PinCodeModalV2
            callback={(success: boolean) => {
              if (success && link) {
                navigate(link);
              }
            }}
            parentalRating={parentalRating}
            context={UIDAPresentationTypeEnum.applications}
            description={parentalRating && <DescriptionWithAge age={parentalRating} />}
            type={PinCodeModalTypes.RestrictedContentParental}
          />,
        );
        return;
      }

      // temp, better render <a> instead of <Link>
      if (link && link.includes('http')) {
        e.preventDefault();
        window.open(link, '_blank');
      }

      if (link && link.includes('details')) {
        // TODO: should be done in the next iteration
        // if (!(currentAsset instanceof LinkAsAsset)) {
        //   globalStorage.setDetailsAsset(currentAsset);
        // }
        // to send the user event after clicking on an AssetCardLink which should open the details page
        userActionEvents(UserActionType.details, data?.id, mediaTypeParam);
      }
    },
    [modalContext, link, parentalRating, isRecord, isSeriesType, settingsStore, isCollectionInAppAdultLink, isAdult],
  );
};

export { useCollectionCardClickHandler };
