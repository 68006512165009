import { useCallback, useRef } from 'react';

import { useHandleRef } from '../useHandleRef/useHandleRef';

interface ResultProps {
  ref: React.RefObject<HTMLDivElement | null>;
  handleRef: (value: HTMLDivElement | null) => void;
}

export function useCardRef(itemWidth: number, imageHeight: number): ResultProps {
  const ref = useRef<HTMLDivElement | null>(null);
  const setRef = useCallback((value: HTMLDivElement | null) => {
    ref.current = value;
  }, []);
  const handleRef = useHandleRef({ setRef, itemWidth, imageHeight });

  return { ref, handleRef };
}
