import React, { useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { observer } from 'mobx-react';

import { useEboxAppConfiguration } from '@kaltura-ott/tvpil-shared';

import Img from 'components/presenters/Img/Img';
import { useEboxAssetClickHandler } from 'components/widgets/CardView/Collection/CollectionCard/hooks/useEboxAssetClickHandler/useEboxAssetClickHandler';

import {
  useAssetCardBodyResolveData,
  useHandleRef,
  useMouseEnterHandle,
  useMouseLeaveHandle,
  useSetIndexToListStore,
} from '../AssetCardBody/hooks';
import { useAssetCardContext } from '../AssetCardContext/AssetCardContext';

import styles from './EboxAssetCard.module.scss';

function EboxAssetCard() {
  const {
    listStore,
    resolvedProps: { title },
  } = useAssetCardContext();

  useSetIndexToListStore();
  const ref = useRef<HTMLDivElement | null>(null);
  const setRef = useCallback((value: HTMLDivElement | null) => {
    ref.current = value;
  }, []);
  const { itemImageUrl, defaultImage, height, width } = useAssetCardBodyResolveData();

  const onMouseEnter = useMouseEnterHandle({ ref });
  const onMouseLeave = useMouseLeaveHandle();
  const handleRef = useHandleRef({ setRef });
  const clickHandler = useEboxAssetClickHandler();
  const configuration = useEboxAppConfiguration();
  const eBoxHostLink = configuration?.host ?? '';

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <Link
      className={cn(styles.assetWrapper, styles[`type-${listStore.subtype}`])}
      onClick={clickHandler}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseEnter}
      role='presentation'
      style={{
        minHeight: height,
        width,
      }}
      to={eBoxHostLink}
    >
      <div ref={handleRef} className={styles.assetInner}>
        <div className={styles.contentWrapper}>
          <div className={cn(styles.imageWrapper)}>
            {itemImageUrl && <Img alt={title} srcList={[itemImageUrl, defaultImage]} />}
          </div>
        </div>
      </div>
    </Link>
  );
}

export default observer(EboxAssetCard);
