import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { CollectionGridMediaAssetWithEpisodes } from 'types';

import {
  CollectionAsset,
  CollectionSeriesAsset,
  isCollectionProgramAsset,
  useEboxAppConfiguration,
} from '@kaltura-ott/tvpil-shared';

import { useCardRef, useMouseEnterHandle, useMouseLeaveHandle } from 'components/presenters/Card/hooks';
import { useRailContext } from 'components/widgets/RailV2/components/RailProviderV2/RailProviderV2';
import { useWidgetContext } from 'context/widgetProvider/widgetProvider';
import { useExternalProviderClickHandler } from 'hooks/common/useExternalProviderClickHandler/useExternalProviderClickHandler';
import { getSeasonEpisodeTitleV2 } from 'utils';

import { useCollectionCardChannelInfo } from '../useCollectionCardChannelInfo/useCollectionCardChannelInfo';
import { useCollectionCardClickHandler } from '../useCollectionCardClickHandler/useCollectionCardClickHandler';
import { useCollectionCardHandleClickInfo } from '../useCollectionCardHandleClickInfo/useCollectionCardHandleClickInfo';
import { useCollectionCardImages } from '../useCollectionCardImages/useCollectionCardImages';
import { useCollectionCardLink } from '../useCollectionCardLink/useCollectionCardLink';
import { useCollectionCardMainProps } from '../useCollectionCardMainProps/useCollectionCardMainProps';
import { useCollectionCardRating } from '../useCollectionCardRating/useCollectionCardRating';

interface Props {
  item: CollectionAsset;
  itemWidth: number;
  imageHeight: number;
}

function useCollectionCard({ item, itemWidth, imageHeight }: Props) {
  const intl = useIntl();
  // using version v2 of the rail provider
  const { onHover, nextButtons } = useRailContext();
  const {
    settings: { shouldShowRating, shouldShowEntitlementIndicator, imageType },
    type,
  } = useWidgetContext();
  const {
    isTypeOfSeries,
    isFuture,
    isPurchasable,
    isLinkAsAsset,
    isCatchupSeries,
    isChannelAsset,
    isSingleVod,
    isAssetPlayOnExternalApp,
    isProgramAsset,
    contentMarker,
    isCollectionEpisode,
    isCollectionInAppAdultLink,
    adult,
    isVod,
    isEboxLinkAsAsset, // temporary solution for o2 CZ. After leaving o2 CZ must be removed
    externalProvider,
    providerName,
  } = useCollectionCardMainProps({
    data: item,
    isGrouped: false,
  });
  const configuration = useEboxAppConfiguration();
  const eBoxHostLink = configuration?.host || '';
  const isAdult = adult || false;
  const isExternalProviderAsset = Boolean(providerName) && isAssetPlayOnExternalApp;
  const isAsPlayAsset = !isTypeOfSeries && !isFuture && !isPurchasable && !isLinkAsAsset;
  const isFutureProgram = isFuture && !isCatchupSeries;

  const { link } = useCollectionCardLink({
    data: item,
    isSingleVod,
    isFutureProgram,
    collectionType: type,
    isLinkAsAsset,
    isAsPlayAsset,
    isChannelAsset,
  });
  // refs
  const handleLinkRef = useRef(null);
  const { ref, handleRef } = useCardRef(itemWidth, imageHeight);
  // handlers
  const onMouseEnter = useMouseEnterHandle({ ref, itemWidth, imageHeight, isGrid: false, onHover, nextButtons });
  const onMouseLeave = useMouseLeaveHandle(onHover);
  const defaultClickHandler = useCollectionCardClickHandler({
    data: item,
    link,
    mediaTypeParam: item.type,
    isExternalProviderAsset,
    isLinkAsAsset,
    isPurchasable,
    collectionType: type,
    isProgramAsset,
    isTypeOfSeries,
    isCatchupSeries,
    isCollectionEpisode,
    isCollectionInAppAdultLink,
    isAdult,
    imageHeight,
  });
  const clickHandler = useExternalProviderClickHandler(externalProvider, defaultClickHandler);
  const { handleClickInfo } = useCollectionCardHandleClickInfo({
    id: item?.id!,
    externalId: undefined,
    mediaTypeParam: item.type,
    isChannelHasNoProgram: false,
    imageHeight,
    isLinkAsAsset,
  });
  // rating
  const { isDisplayRating, assetRating } = useCollectionCardRating({ shouldShowRating, item });
  // images urls
  const { backgroundImageUrl, defaultImageUrl } = useCollectionCardImages({
    item,
    imageType,
    imageWidth: itemWidth,
    imageHeight,
  });
  // title and channelName
  const { title, channelName } = useCollectionCardChannelInfo(item);
  // collectionProgramAsset type checking for AirTime presentation
  const collectionProgramAsset = isCollectionProgramAsset(item) ? item : undefined;
  // checking for play button presentation
  const isDisplayCartIcon = (isPurchasable && !isTypeOfSeries && shouldShowEntitlementIndicator) || false;
  // checking for info button presentation
  const channelHasProgram = Boolean(!isChannelAsset && !isLinkAsAsset);

  // season / episode title definition
  let seasonEpisodeTitle = '';

  const isEpisodeInfo = (isProgramAsset || isCollectionEpisode) && !isCatchupSeries;
  if (isEpisodeInfo) {
    seasonEpisodeTitle = getSeasonEpisodeTitleV2(intl, item as CollectionGridMediaAssetWithEpisodes, ':', true);
  }

  // seriesId definition
  const seriesId = (item as CollectionSeriesAsset)?.seriesId!;

  // content marker
  const isDisplayContentMarker = isVod && !!contentMarker && !providerName;

  return {
    id: item.id,
    link: isEboxLinkAsAsset ? eBoxHostLink : link,
    title,
    isAdult,
    handleRef,
    assetRating,
    channelName,
    clickHandler,
    onMouseEnter,
    onMouseLeave,
    isAsPlayAsset,
    handleLinkRef,
    handleClickInfo,
    isDisplayRating,
    isDisplayCartIcon,
    defaultImageUrl,
    backgroundImageUrl,
    collectionProgramAsset,
    isFutureProgram,
    seriesId,
    channelHasProgram,
    isExternalProviderAsset,
    seasonEpisodeTitle,
    isDisplayContentMarker,
    contentMarker,
    isLinkAsAsset,
    shoudHideForEboxAsset: isEboxLinkAsAsset, // temporary solution for o2 CZ. After leaving o2 CZ must be removed
  };
}

export { useCollectionCard };
