import { useCallback } from 'react';

import { fetchEboxToken, useEboxAppConfiguration } from '@kaltura-ott/tvpil-shared';

import { useAppStore, useDeviceStore, useUserStore } from 'hooks';

export const useEboxAssetClickHandler = () => {
  const {
    appStore: {
      params: {
        dmsSetting: { partnerId },
      },
    },
  } = useAppStore();
  const { userData } = useUserStore();
  const { householdDevice } = useDeviceStore();
  const configuration = useEboxAppConfiguration();
  const { brandId, udid } = householdDevice || {};
  const { externalId } = userData || {};
  const { host } = configuration || {};

  const handleEboxClick = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      if (!host) {
        return;
      }

      let token;

      if (!(brandId && externalId && udid)) {
        return;
      }

      try {
        token = await fetchEboxToken({
          udid,
          brandId,
          partnerId,
          householdId: externalId,
        });
      } catch (err) {
        console.warn(err);
      }

      if (token) {
        const redirectUrl = `${host}?token=${token}`;
        window.location.href = redirectUrl;
      } else {
        window.location.href = host;
      }
    },
    [brandId, udid, externalId, host, partnerId],
  );

  return handleEboxClick;
};
