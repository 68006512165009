import React from 'react';
import { observer } from 'mobx-react';

import AssetCard from '../AssetCard';
import { useAssetCardContext } from '../AssetCardContext/AssetCardContext';
import AssetCardLogo from '../AssetCardLogo/AssetCardLogo';
import EboxAssetCard from '../EboxAssetCard/EboxAssetCard';

function AssetCardResolveRendering() {
  const {
    resolvedProps: { isLogoAsset, isRegularAsset, isEboxLinkAsAsset },
  } = useAssetCardContext();

  if (isEboxLinkAsAsset) {
    // temporary solution for o2 CZ. After leaving o2 CZ must be removed
    return <EboxAssetCard />;
  }

  if (isLogoAsset) {
    return <AssetCardLogo />;
  }

  if (isRegularAsset) {
    return <AssetCard />;
  }

  return null;
}

export default observer(AssetCardResolveRendering);
